import React, { useState,useRef } from 'react'
import { Link } from 'react-router-dom'
import QuickContact from './QuickContact';
import QuickLink from './QuickLink';
import {Helmet} from "react-helmet";


import ServiceDetail from './ServiceDetail';
 function Tv () {
  const repair = useRef();
   const install = useRef();
  function handleRepair() {
  repair.current.scrollIntoView({ behavior: "smooth" });
}
function handleInstall() {
  install.current.scrollIntoView({ behavior: "smooth" });
}
     
    return <>
     <Helmet>
    <title>Snappy: LCD/LED TV Repair in Ghaziabad, Noida</title>

<meta name="description" content=""/>
<meta name="keywords" content=""/>


<meta name="google-site-verification" content="zZ2rE2HaxXwuRJC8cgm91PMb8xYxDYsWwLkUIck7d8E" />
    </Helmet>
    <section id="What_offer">
        <div className="container text-center ">
          <div className="row text-center" style={{marginTop: '15px', verticalAlign: 'middle'}}>
            <div className="col-md-8 col-sm-6 col-xs-12" style={{verticalAlign: 'middle'}}>
              <h1 className="text-left" style={{fontSize: '35px', fontWeight: 'bold', marginBottom: '55px'}}>LCD - LED - Plasma - Curved TV</h1>
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">
                <div className="icon_box_one">
                  <div className="icons"><img style={{borderRadius: '10px'}} src="../assets/images/service_detail/tv-1.png" alt="Bathroom" /></div>
                  <div className="box_content">
                     <Link onClick={handleRepair}><h4>Repair</h4></Link>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">
                <div className="icon_box_one">
                  <div className="icons"><img style={{borderRadius: '10px'}} src="../assets/images/service_detail/tv-1.png" alt="Bathroom" /></div>
                  <div className="box_content">
                     <Link onClick={handleInstall}><h4>Installation/Unistallation</h4></Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <img src="../assets/images/service/tv.png" className="img-responsive" style={{borderRadius: '30px'}} alt=" "/>
            </div>
          </div>
        </div>
      </section>
        <section id="What_offer">
        <div className="container text-justify">
        
          <div className="row">
        <div className="col-md-8">
          <ServiceDetail service={"TV"} category={"repair"} type={repair} heading={'Repair'} />
            <ServiceDetail service={"TV"} category={"install"} type={install} heading={'Installation/Uninstallation'} />
          
          
          
        </div>
         <div className="col-md-4"   style={{padding:'20px'}} >
           
            <div className="row text-left  banner_content service_banner_layer" id='service_banner'>
              <div className="col-md-12 col-sm-12 col-xs-12 " style={{padding:'20px'}}>
               <h1 className="service-heading">Book LCD, LED, Plasma, Curved TV Repair and Service Today </h1>
                <p> We're your most-trusted option for fast, quick, and easy local service, repair of your LCD TV, LED TV, and Plasma TV. We are experts in Repair of all TV Brands and Models. </p>
                <h2>Why Choose Us? </h2><br />
                <ul className="featurs_list">
                  <li><i className="fa fa-check-square-o" aria-hidden="true" /> Certified Technician</li>
                  <li><i className="fa fa-check-square-o" aria-hidden="true" /> Same Day Service</li>
                  <li><i className="fa fa-check-square-o" aria-hidden="true" /> Best Quality Service</li>
                  <li><i className="fa fa-check-square-o" aria-hidden="true" /> Best Pricing</li>
                </ul>
               
              </div>
             <QuickContact/>
            </div>
          <QuickLink/>
        
          </div>
      </div>
        </div>
      </section>
       
       <section id="What_offer">
        <div className="container text-justify">
          <h1 className="panel-heading text-center">Why Snappy best choice for you?</h1>
          <p>Every home deserves best-in-class efficiency, peace of mind getting their appliances repaired or serviced. Given its critical role in your home, it’s imperative you only entrust it to a reputable home appliances repair &amp; service company like Snappy. <br /><br />
            Each service engineers on the Snappy team undergoes a rigorous background check to assess whether they have what it takes to meet and beat clientele expectations. They’re fully licensed, insured, and certified.<br /><br />
            Our efficient work helps you in saving energy cost, long lifespan through quality workmanship and Durable Worry free performance of your home appliance. We use superior technology and quality spares to make your appliances as good as new. <br /><br /> 
            Backed by over twenty years of repair &amp; service experience and a plethora of satisfied clients, we only exists for one reason to deliver unrivalled home appliances services that keep your electrical appliance at peak performance consistently. <br /><br /> 
            What’s more, we know the industry is ever-evolving. To keep our team on top of emerging the Best Home appliance services in Delhi NCR trends and innovations, we encourage our AC technicians to pursue further training and development through specialized in-house programs. That helps them enhance their skills and efficiency, allowing them to offer you nothing but the best Electrical appliances service in Delhi, Ghaziabad, Noida and NCR.
          </p>
        </div>
      </section>
      <section id="service-faq">
        <div className="container text-center">
          <h1 className="panel-heading">Frequently Asked Questions</h1>
          <div className="row text-left">
            <div className="col-md-6 col-sm-6 col-xs-12 text-left">
              <div className="qa_box_one">
                <div className="question"><strong>Q.</strong>
                  <h4>When should I repair my TV?</h4>
                </div>
                <div className="answer"><strong>A.</strong>
                  <p>Repair and service is required when your TV has these issues No picture Display, Colour Issue, Haze Image, Loud Sound, Half Image, No Display, Lines on TV and TV not switching on Snappy service specialist will fix your TV in minutes. 
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12 text-left">
              <div className="qa_box_one">
                <div className="question"><strong>Q.</strong>
                  <h4>What is the process of TV Installation?</h4>
                </div>
                <div className="answer"><strong>A.</strong>
                  <p>Important procedure to be followed while mounting and installing LED or LCD TV is correct wall selection, accurate distance from ground, distance between seating and screen, correct viewing angle.  Wall mounting saves floor space that would have otherwise been occupied by the TV stand.  TV installation includes fixing bracket, TV mounting in the bracket.
                  </p>
                </div>
              </div>
            </div></div><div className='row'>
          <div className="col-md-6 col-sm-6 col-xs-12 text-left">
            <div className="qa_box_one">
              <div className="question"><strong>Q.</strong>
                <h4>What is the process of TV Uninstallation? </h4>
              </div>
              <div className="answer"><strong>A.</strong>
                <p>Television unistallation has to done with at most care so our trained service engineer first removes all cables from the TV, removes the protection which is usually secured to the mount by a protective element, lift the TV off the mount , remove the mount and finally close the holes and your TV Safely.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12 text-left">
            <div className="qa_box_one">
              <div className="question"><strong>Q.</strong>
                <h4>How much does it cost to repair my device? </h4>
              </div>
              <div className="answer"><strong>A.</strong>
                <p>It really depends on what is wrong with your device. Well, we know how it feels when something goes wrong and we will give you a break by offering a FREE estimate. That will help you to decide what to do.</p>
              </div>
            </div>
          </div>
          <div className='row text-center'>
              <div className='col-md-12'>
                <h1 className='text-red'>Still Have Questions?</h1>
                <h2>Give us a call at <strong>+91-99997 94426</strong></h2>
                <p>Snappy is dedicated to providing you with the best service possible.<br></br> We look forward to working with you!</p>
              </div>
            </div></div>
        </div>
      </section>
    </>
  }

export default Tv;