import React, { Component } from 'react'

export default class Trust extends Component {
  render() {
    return (
      <section id="trust-security">
        <div className="container text-center">
          <h1 className="panel-heading">Your Trust and Security</h1>
          <div className="row text-left">
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="icon_box_one">
                <div className="icons"><img src="../assets/images/time.png" alt="SAVES" /></div>
                <div className="box_content">
                  <h4>SAVES YOU TIME</h4>
                  <p>Scheduele your services through website, call and whatsapp.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="icon_box_one">
                <div className="icons"><img src="../assets/images/Safety.png" alt="Safety" /></div>
                <div className="box_content">
                  <h4>For Your Safety</h4>
                  <p>All of our engineers undergo rigorous identity checks and personal interviews. Your safety is even our concern.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="icon_box_one">
                <div className="icons"><img src="../assets/images/best.png" alt="Best" /></div>
                <div className="box_content">
                  <h4>Best-Rated Professionals</h4>
                  <p>Our experienced engineers perform their work with dedication and perfection. We appreciate your reviews about the service.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="icon_box_one">
                <div className="icons"><img src="../assets/images/Equipped.png" alt="Equipped" /></div>
                <div className="box_content">
                  <h4>We Are Well Equipped</h4>
                  <p>Let us know if you have any specific requirement, our professionals are well equipped with all the tools and spare-parts.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="icon_box_one">
                <div className="icons"><img src="../assets/images/touch.png" alt="Always" /></div>
                <div className="box_content">
                  <h4>Always In Touch</h4>
                  <p>Book your service online on one tap, keep a track of your service status and also keep in touch with your technician.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="icon_box_one">
                <div className="icons"><img src="../assets/images/cash.png" alt="cash" /></div>
                <div className="box_content">
                  <h4>Cash-Free Facility</h4>
                  <p>Pay through secure online mode only after your job is done.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
