const data = [
    {
        id:1,
        title: "MEP Services",
        link: "mechanical-electrical-plumbing-contractor",
        
    },
    {
        id:2,
        title: "HVAC Services",
        link:"air-conditioner",
        
    },
    {
        id:3,
        title: "Plumbing Services",
        link: "plumbing",
        
    },
    {
        id:4,
        title: "Electrical Contracting Services",
        link: "electrical-contractor",
        
    },
    {
        id:5,
        title: "Fire Fitting Services",
        link: "solar-appliances",
        
    },
    {
        id:6,
        title: "HT/LT Line ",
        link:"line-work",
        
    },    
   
];

export default data;