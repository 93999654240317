import logo from './logo.svg';
import './App.css';
import Header from './Common/Header';
import Footer from './Common/Footer';
import Home from './Home/Home';

function App() {
  return (
   <Home/>
    
  );
}

export default App;
